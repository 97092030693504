import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import styles from './menu-toggle.module.scss'

interface MenuToggleProps {
  className?: string
}

const MenuToggle: FunctionComponent<MenuToggleProps> = ({ className }) => {
  return <div
    className={clsx(
      styles.menuToggleContainer,
      className
    )}
  >
    <label htmlFor="toggle-checkbox-menu" className={styles.menuToggle}>
      <span className={styles.menuToggleLineTop}></span>
      <span className={styles.menuToggleLineMiddle}></span>
      <span className={styles.menuToggleLineBottom}></span>
    </label>
  </div>
}

export default MenuToggle
