import { NavigationItem } from '@sporza/design-system/organisms/navigation'
import { addTrailingSlash } from '@sporza/utils/urls'
import { useEffect, useState } from 'react'

const useMenuActivation = (
  items: NavigationItem[],
  location?: any,
  tags: string[] = [],
  rootOnly = false
) => {
  const [locationState, setLocationState] = useState(location)

  useEffect(() => {
    setLocationState(window.location)
  }, [])

  let foundActive = false
  let foundMatch = false

  const path = addTrailingSlash(locationState?.pathname)

  const hasActiveItem = (items: NavigationItem[]) => items?.length > 0 && items.filter((item: NavigationItem) => item.active)?.length > 0

  const getPath = (href: string) => {
    if (href){
      const targetUrl = new URL(href, 'http://dummy')
      return addTrailingSlash(targetUrl.pathname)
    }
  }

  let mobileItems

  const activatedItems: any = (items: NavigationItem[], level = 1) => {
    return items && items.map(item => {
      let active = false

      if (item?.href && !foundActive && !foundMatch) {
        const itemPath = getPath(item.href)
        const isInSportEvents = item.iconBefore === 'arrow-right'

        const matchesCurrentLocation =
          itemPath === path
          || (
            path.startsWith('/nl/podcasts')
            && itemPath?.startsWith('/nl/podcasts')
          )

        const matchesArticleFirstTag = () => {
          if (foundMatch)
            return false

          const firstTag = tags[0]
          return !!firstTag && getPath(firstTag.href) === itemPath
        }
        active = !isInSportEvents && (matchesCurrentLocation || matchesArticleFirstTag())

        if (matchesArticleFirstTag()){
          foundMatch = true
        }
      }

      if (item.title === 'meer sport'){
        item.bypassRootOnly = true
      }

      if (item.bypassRootOnly) {
        item.items = item.items?.map((subItem: NavigationItem) => {
          return {
            ...subItem,
            bypassRootOnly: true
          }
        })
      }

      const processedItems = (item.bypassRootOnly || !rootOnly) && item.items && activatedItems(item.items, level+1)

      if (active || processedItems && hasActiveItem(processedItems)){
        mobileItems = processedItems
      }

      foundActive = active

      return {
        ...item,
        items: processedItems,
        active: active || hasActiveItem(processedItems) // dont expand for meer sporten
      }
    })
  }

  return {
    activeItems: activatedItems(items),
    activeMobileItems: mobileItems
  }
}

export { useMenuActivation }
