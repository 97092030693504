import React, { FunctionComponent } from 'react'

interface TrackingHeadProps {
  tagManagerContainerUrl?: string,
  trackingScriptBaseUrl?: string,
  version?: string,
}

const TrackingHead: FunctionComponent<TrackingHeadProps> = (
  {
    tagManagerContainerUrl,
    trackingScriptBaseUrl= '//t.vrt.be',
    version = 'latest'
  }
) => {
  // if (!tagManagerContainerUrl) {
  //   console.warn('tag manager url not defined', tagManagerContainerUrl)
  //
  //   return null
  // }
  //
  // return <script async src={tagManagerContainerUrl} />

  return <>
    <script src={`${trackingScriptBaseUrl}/${version}/vrtebahelper.min.js`}/>
    <script src={`${trackingScriptBaseUrl}/${version}/vrtebatemplates.min.js`}/>
  </>
}

export default TrackingHead

export type {
  TrackingHeadProps
}
