import { pageImpressionEvent } from '../events'

const trackPageChange = () => {
  // Page impression event
  pageImpressionEvent()

  // Smartocto - https://docs.smartocto.com/help/tentacles-script-setup
  window.postMessage('activateTentacles')
}

export {
  trackPageChange
}
