import { useEffect } from 'react'

import { trackPageChange } from '../'

const useTracking = ({ location }: any): void => {
  useEffect(() => {
    trackPageChange()
  }, [location])
}

export {
  useTracking
}
