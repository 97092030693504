import {
  addEventListeners,
  config as ssoConfig,
  createLoginConfigObject,
  createLoginScriptTag,
  displayProfileButton,
  getUserData,
} from './helpers'

const initSSO = () => {
  setTimeout(() => {
    createLoginConfigObject()
      .then(createLoginScriptTag)
      .then(() => {
        displayProfileButton()
        addEventListeners()
      })
  })
}

const userIsLoggedIn = () => {
  return getUserData() !== undefined
}

export {
  initSSO,
  userIsLoggedIn,
  ssoConfig
}
