import type { HeadersFunction, LinksFunction, LoaderFunction, MetaFunction } from '@remix-run/node'

import { json } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches,
} from '@remix-run/react'
import { designSystemBaseUrl, environment } from '@sporza/config'
import sporzaGlobalStylesheet from '@sporza/design-system/globals/globals.scss?url'
import resetStylesheet from '@sporza/design-system/globals/site-reset.scss?url'
import { Advertisement, initAds } from '@sporza/design-system/molecules/advertisement'
import { AdPosition, AdvertisementType } from '@sporza/design-system/molecules/advertisement/advertisement'
import Footer from '@sporza/design-system/organisms/footer'
import Header from '@sporza/design-system/organisms/header'
import { useMenuActivation } from '@sporza/hooks'
import { TrackingFooter, TrackingHead, useTracking } from '@sporza/tracking'
import clsx from 'clsx'
import { StrictMode, useEffect, useState } from 'react'

import { PageType } from '~/config'
import { getSeo, toVersion2 } from '~/features/seo'
import { initSSO, ssoConfig } from '~/features/sso'
import { getEtag } from '~/helpers'
import bffClient from '~/services/bff'
import { useSiteConfigStore } from '~/store'
import pageGlobalStyles from '~/styles/page-type/globals.scss?url'
import rootStyles from '~/styles/root.module.scss'

const ssoEnv = ssoConfig[environment as keyof typeof ssoConfig]
const [seoMeta] = getSeo()

export const meta: MetaFunction = () => {
  return toVersion2(seoMeta)
}

export const links: LinksFunction = () => {
  return [
    { rel: 'preconnect', href: designSystemBaseUrl },
    { rel: 'preload', href: `${designSystemBaseUrl}/fonts/FormaDJRMicro-Regular.woff2`, as: 'font', type: 'font/woff2', crossOrigin: 'anonymous' },
    { rel: 'preload', href: `${designSystemBaseUrl}/fonts/F37Ginger-Regular.woff2`, as: 'font', type: 'font/woff2', crossOrigin: 'anonymous' },
    { rel: 'preload', href: `${designSystemBaseUrl}/fonts/FormaDJRMicro-Medium.woff2`, as: 'font', type: 'font/woff2', crossOrigin: 'anonymous' },
    { rel: 'preload', href: `${designSystemBaseUrl}/fonts/F37Ginger-Bold.woff2`, as: 'font', type: 'font/woff2', crossOrigin: 'anonymous' },
    { rel: 'preload', href: `${designSystemBaseUrl}/fonts/FormaDJRMicro-Bold.woff2`, as: 'font', type: 'font/woff2', crossOrigin: 'anonymous' },
    { rel: 'preload', href: `${designSystemBaseUrl}/fonts/FormaDJRMicro-Italic.woff2`, as: 'font', type: 'font/woff2', crossOrigin: 'anonymous' },
    { rel: 'preload', href: `${designSystemBaseUrl}/fonts/FormaDJRMicro-BoldItalic.woff2`, as: 'font', type: 'font/woff2', crossOrigin: 'anonymous' },
    { rel: 'stylesheet', href: resetStylesheet },
    { rel: 'stylesheet', href: sporzaGlobalStylesheet },
    { rel: 'stylesheet', href: pageGlobalStyles },
    { rel: 'preconnect', href: 'https://images.vrt.be' },
    { rel: 'preconnect', href: 'https://cookie.vrt.be' },
    { rel: 'preconnect', href: 'https://sso.vrt.be' },
    { rel: 'shortcut icon', type: 'image/x-icon', href: '/favicon.ico' },
    { rel: 'stylesheet', href: ssoEnv?.playerEventHandlerStyling }
  ]
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return loaderHeaders
}

export const loader: LoaderFunction = async () => {
  const menuResult = await bffClient.getPath('/content/menu')

  return json(
    {
      config: {
        tagManagerContainerUrl: process.env.TAG_MANAGER_CONTAINER_URL,
        trackingScriptBaseUrl: process.env.TRACKING_SCRIPT_BASE_URL,
        designSystemBaseUrl: process.env.DESIGN_SYSTEM_BASE_URL,
        apiBaseUrl: process.env.API_BASE_URL
      },
      showLogin: menuResult?.header?.showLogin,
      azLink: menuResult?.header?.azLink,
      mainNavigationItems: menuResult?.header?.main,
      mainNavigationRightItems: menuResult?.header?.mainRight,
      topNavigationItems: menuResult?.header?.top,
      footerNavigationItems: menuResult?.footer
    },
    {
      headers: {
        ETag: getEtag(menuResult)
      }
    }
  )
}

export default function App() {
  const data = useLoaderData()

  const initSiteConfig = useSiteConfigStore((state) => state.initSiteConfig)

  useEffect(() => {
    initAds()
    initSSO()

    initSiteConfig(data.config)
  }, [])

  const location = useLocation()
  useTracking({ location })

  const matches = useMatches()
  const classes = matches
    .find(item => 'bodyClassNames' in item.data)?.data.bodyClassNames

  const tags = matches
    .find((item) => 'page' in item.data && 'tags' in item.data.page)?.data.page.tags

  const pageType = matches
    .find((item) => 'page' in item.data && 'pageType' in item.data.page)?.data.page.pageType

  const [ adOffset, setAdOffset ] = useState<number>(0)
  const rootOnlyMenu = pageType === PageType.Competition

  const {
    activeItems,
    activeMobileItems
  } = useMenuActivation(data.mainNavigationItems, location, tags, rootOnlyMenu)

  const {
    activeItems: activeRightItems,
    activeMobileItems: activeMobileRightItems
  } = useMenuActivation(data.mainNavigationRightItems, location)

  return (
    <StrictMode>
      <html lang="nl">
      <head>
        <Meta/>
        <Links/>
        <TrackingHead trackingScriptBaseUrl={data.config.trackingScriptBaseUrl} />
      </head>
      <body className={clsx(
        classes,
        rootOnlyMenu && rootStyles.rootOnly
      )}>
      <Advertisement position={AdPosition.Top} setAdOffset={setAdOffset} adType={AdvertisementType.LargeLeaderboard} />
      <Header
        showLogin={data.showLogin}
        azLink={data.azLink}
        topNavigationItems={data.topNavigationItems}
        mainNavigationItems={activeItems}
        mobileNavigationItems={ activeMobileItems || activeMobileRightItems }
        mainNavigationRightItems={activeRightItems}
        offset={adOffset}
      />
      <Outlet/>
      <Footer footerNavigationItems={data.footerNavigationItems}/>
      <ScrollRestoration/>
      <Scripts/>
      <script src={ssoEnv?.combinedEventHandler} />
      <TrackingFooter
        trackingScriptBaseUrl={data.config.trackingScriptBaseUrl}
        tagManagerContainerUrl={data.config.tagManagerContainerUrl}
      />
      </body>
      </html>
    </StrictMode>
  )
}
