import type { TrackingHeadProps } from './head'

import React, { FunctionComponent } from 'react'

interface TrackingFooterProps extends TrackingHeadProps {
  trackingScriptBaseUrl?: string
  version?: string
}

const TrackingFooter: FunctionComponent<TrackingFooterProps> = (
  {
    tagManagerContainerUrl,
    trackingScriptBaseUrl,
    version = 'latest'
  }
) => {
  if (!trackingScriptBaseUrl) {
    console.warn('tracking script url not defined', trackingScriptBaseUrl)

    return null
  }

  return <>
    {
      // TODO: check to move `tagManagerContainerUrl` to the head component when react 18 has fixed hydration errors
      // https://github.com/reactjs/reactjs.org/issues/4656#issuecomment-1126180807
      tagManagerContainerUrl
        ? <script async src={tagManagerContainerUrl} />
        : null
    }
    <script async src={`${trackingScriptBaseUrl}/${version}/vrteba.min.js`}/>
  </>
}

export default TrackingFooter
