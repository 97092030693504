import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type SiteConfigState = {
  siteconfig: Record<string, any>
  initSiteConfig: (config: any) => void
  addSiteConfig: (configKey: string, configValue: any) => void
}

const useSiteConfigStore = create<SiteConfigState>()(
  devtools(
    (set) => ({
      siteconfig: {},
      initSiteConfig: (siteConfig) => {
        if (!siteConfig) {
          return
        }

        set(
          () => ({
            siteconfig: {
              ...siteConfig
            }
          }),
          false,
          'initSiteConfig'
        )
      },
      addSiteConfig: (key, config) => {
        if (!key || !config) {
          return
        }

        set(
          (state) => ({
            siteconfig: {
              ...state.siteconfig,
              [key]: config
            }
          }),
          false,
          'addSiteConfig'
        )
      }
    })
  )
)

export {
  useSiteConfigStore
}

export type {
  SiteConfigState
}
